import { mapState } from 'vuex';
// import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import { api } from '@/api/axios-base';
import { apiMixin } from '../../../shared/mixins/apiMixin';
import { dateUtilsMixin } from '../../../shared/mixins/dateUtilsMixin';
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin';
import { generalFetchingMixin } from '../../../shared/mixins/generalFetchingMixin';
import { getItem, postItem } from '../../../../api/api-methods';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { fetchStudents } from '../../../employees/helpers/reportCardOptions';
import {
  fetchFiltersWithData,
  fetchAccounts,
  // fetchEducationalInstitutions,
  fetchDebts,
  postIncome,
  putIncome,
  putDebt,
  increaseAccountBalance,
  decreaseAccountBalance,
  sendProofOfIncome,
  postCreateIncomeBill,
  CfdiTypes,
  FiscalRegimen,
  PaymentMethods,
  Currencies,
  PaymentForms,
  putTaxInfo,
} from '../../helpers/incomeOptions';
import {
  getFullName,
  createFormDataFromObject,
} from '../../../shared/helpers/dataUtils';
import { postDocument } from '../../../shared/helpers/dataOptions';

import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnDownloadDocItem from '../../../shared/components/KnDownloadDocItem.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from '../../helpers/incomeStorage';

export default {
  components: {
    KnAutoComplete,
    KnBackToTopButton,
    KnCheckBox,
    KnDocItem,
    KnDownloadDocItem,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnLocalAlert,
    KnSelect,
    KnTextArea,
    KnTextField,
  },
  mixins: [
    apiMixin,
    generalFetchingMixin,
    dateUtilsMixin,
    fileUtilsMixin,
    validationFormMixin,
  ],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bkEntity: null,
      bkReadonly: false,
      valueDeterminate: 100,
      routerName: 'Ingresos',
      valid: true,
      income: {
        alumno: null,
        adeudo: [],
        categoria: null,
        sub_total: 0.0,
        total_impuestos: 0.0,
        costo_envio: 0.0,
        total_descuento: 0.0,
        total_penalizaciones: 0.0,
        total_comision: 0.0,
        total_ingreso: 0.0, // se va a calcular de la suma de subtotal + impuestos menos el valor de descuento
        descuento_aplicado: false,
        penalizacion_aplicada: false,
        is_impuestos_aplicados: false,
        is_comision_aplicada: false,
        penalizacion: null,
        forma_de_pago: null,
        moneda: 1,
        cuenta_destino: null,
        estatus: 1,
        comprobantes: [],
        institucion_educativa: null,
        autor: null, // debe usar el de usuario logueado
        estatus_sistema: true,
        comentarios: '',
        facturado: false,
        id_cfdi: '',
      },
      applyTaxes: false,
      applyPenalty: false,
      applyDiscount: false,
      applyCommission: false,
      // isCardPayment: false,
      incomeId: null,
      students: [],
      institutions: [],
      accounts: [],
      paymentMethods: [],
      incomeCategories: [],
      coins: [],
      incomeStatus: [],
      showDebts: false,
      studentDebts: [],
      debtSelected: {},
      debtsSelected: [],
      debt: {},
      penaltyAmount: 0.0,
      penalty_total: 0.0,
      discountAmount: 0.0,
      commissionAmount: 0.0,
      commissionPercentage: 3.5,
      totalCommission: 0.0,
      debtStatus: [],
      statusDebt: 1,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      incomeDate: null,
      receipt: null,
      receiptIdToDelete: null,
      toBill: false,
      taxInfoIssuer: null,
      taxInfoReceiver: null,
      loadingDebts: false,
      /***** variables para datos fiscales de tutor */
      openTaxInfo: false,
      cfdiTypes: CfdiTypes,
      fiscalRegimen: FiscalRegimen,
      paymentMethodsF: PaymentMethods,
      currencies: Currencies,
      paymentForms: PaymentForms,
      invoiceUse: [],
      tutorInfo: {
        datos_personales: null,
        parentesco: null,
      },
      taxInfoMessage: null,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'currentInstitution']),
    isNewMode() {
      return this.entity === null || this.bkEntity === null;
    },
    title() {
      return this.isNewMode ? 'Registro de ingresos' : 'Detalle de ingresos';
    },
    penaltyPercent() {
      return `${this.penaltyAmount * 100}%`;
    },
    discountPercent() {
      return `${this.discountAmount * 100}%`;
    },
    isReadonly() {
      return this.readonly || this.bkReadonly;
    },

    successAlertText() {
      return this.isNewMode
        ? 'Ingreso registrado con exito!'
        : 'Ingreso actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    debtText() {
      return this.isNewMode
        ? 'Adeudos pendientes de pago'
        : this.hasDebts
        ? 'Adeudos relacionados'
        : 'Adeudos pendientes de pago';
    },
    hasDebts() {
      return this.isNewMode
        ? this.debtsSelected !== null && this.debtsSelected.length > 0
        : (this.entity.adeudo !== null && this.entity.adeudo.length > 0) ||
            (this.debtsSelected !== null && this.debtsSelected.length > 0);
    },
    mandatoryDiscountRules() {
      return this.income.descuento_aplicado ? [this.rules.required] : [];
    },
    mandatoryPenaltyRules() {
      return this.income.penalizacion_aplicada ? [this.rules.required] : [];
    },
    mandatoryTaxesRules() {
      return this.applyTaxes ? [this.rules.required] : [];
    },
    mandatoryInstitutionRules() {
      return this.toBill ? [this.rules.required] : [];
    },
    mandatoryCommissionRules() {
      return this.applyCommission ? [this.rules.required] : [];
    },
    taxSuffix() {
      return this.applyTaxes ? '16%' : '0%';
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
    selectedStudent() {
      return this.students.find((student) => student.id === this.income.alumno);
    },
    isCardPayment() {
      const findedPaymentMethod = this.paymentMethods.find(
        (pm) => pm.id === this.income.forma_de_pago
      );
      return findedPaymentMethod
        ? findedPaymentMethod.dato.toLowerCase().includes('tarjeta')
        : false;
    },
    toBillText() {
      return this.isNewMode
        ? 'Generar factura?'
        : this.income.facturado
        ? 'El ingreso ya se encuenta facturado'
        : 'Generar factura?';
    },
  },
  watch: {
    applyPenalty(value) {
      if (!value) {
        this.income.total_penalizaciones = 0.0;
      }
      this.calculateTotalSimple();
    },
    applyDiscount(value) {
      if (!value) {
        this.income.total_descuento = 0.0;
      }
      this.calculateTotalSimple();
    },
    applyTaxes(value) {
      if (!value) {
        this.income.total_impuestos = 0.0;
      }
      this.calculateTotalSimple();
    },
    isCardPayment(value) {
      // console.log('isCardPayment', value);

      value ? (this.applyCommission = true) : (this.applyCommission = false);
    },
    applyCommission(value) {
      // console.log('applyCommission', value);

      value ? this.calculateCommissionTotal() : this.resetCommission();

      this.calculateTotalSimple();
    },
    toBill: {
      async handler(value) {
        if (value) {
          await this.getTutorTaxInfo();
        }
      },
    },
    selectedStudent() {
      this.toBill = false;
      this.taxInfoReceiver = null;
      this.tutorInfo = {
        datos_personales: null,
        parentesco: null,
      };
    },
  },
  async created() {
    this.loading = true;

    this.bkEntity = this.entity;
    this.bkReadonly = this.readonly;

    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;

    this.income.institucion_educativa = this.institutionId;
    this.income.autor = this.userData.id;
    this.taxInfoIssuer = this.currentInstitution.datos_fiscales;

    const localData = getLocalData();
    if (localData) {
      Object.assign(this.$data, localData.data);
    } else {
      await this.fetchData();

      // console.log('Estudiantes', this.students);
      //this.fetchDebtsById(debt, this.institutionId)
      // console.log('Debit selected', this.debtSelected);
      const today = new Date();
      this.incomeDate = this.getDate(today);
      // console.log('hoy:', this.getDate(today));

      if (!this.isNewMode) {
        // console.log('Se deben llenar los campos', this.entity);
        this.incomeId = this.entity.id;
        this.fillIncome();
      }
      this.showAlert = false;
      setLocalData({
        data: this.$data,
        props: { entity: this.entity, readonly: this.readonly },
      });
    }
  },
  updated() {
    // console.log('Hubo un cambio', this.$data);
    setLocalData({
      data: this.$data,
      props: { entity: this.entity, readonly: this.readonly },
    });
  },
  beforeRouteLeave(to, from, next) {
    // console.log('beforeLeave to: ', to, ' from: ', from);
    removeLocalData();
    next();
  },
  methods: {
    getFullName: getFullName,
    async fetchData() {
      const { ok: okStudents, data: dataStudents } = await fetchStudents({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 2000,
      });
      const { ok: okIncomeCat, data: dataIncomeCat } =
        await fetchFiltersWithData('categoria-ingreso', {
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okPaymentMethod, data: dataPaymentMethod } =
        await fetchFiltersWithData('forma-de-pago', {
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      const { ok: okCoin, data: dataCoin } = await fetchFiltersWithData(
        'moneda',
        {
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        }
      );
      const { ok: okDebtStatus, data: dataDebtStatus } =
        await fetchFiltersWithData('estatus-adeudos', {
          institutionId: this.institutionId,
          data: 'Adeudo pendiente de pago',
          systemStatus: true,
          limit: 5,
        });
      const { ok: okIncomeStatus, data: dataIncomeStatus } =
        await fetchFiltersWithData('estatus-ingreso', {
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      // const { ok: okInstitutions, data: dataInstitutions } =
      //   await fetchEducationalInstitutions({
      //     systemStatus: true,
      //     limit: 100,
      //   });
      const { ok: okAccount, data: dataAccount } = await fetchAccounts({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 100,
      });

      const { ok: okCFDIUse, data: dataCFDIUse } = await fetchFiltersWithData(
        'uso-factura',
        {
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 5,
        },
        'app-personas'
      );

      this.students = okStudents ? dataStudents : [];
      this.incomeCategories = okIncomeCat ? dataIncomeCat : [];
      this.paymentMethods = okPaymentMethod ? dataPaymentMethod : [];
      this.coins = okCoin ? dataCoin : [];
      this.debtStatus = okDebtStatus ? dataDebtStatus : [];
      this.incomeStatus = okIncomeStatus ? dataIncomeStatus : [];
      // this.institutions = okInstitutions ? dataInstitutions : [];
      this.accounts = okAccount ? dataAccount : [];
      this.invoiceUse = okCFDIUse ? dataCFDIUse : [];

      // this.debtSelected = await getItem(
      //   `/app-administracion/filters/adeudos?institucion_educativa=${this.institutionId}`
      // );
    },
    async createDoc() {
      const doc = {
        nombre_documento: `comprobanteIngreso${this.income.categoria}${this.income.alumno}_${this.incomeDate}`,
        documento: this.receipt.file,
        tipo_documento: 2, // Comprobante de ingreso
        autor: this.userData.id,
        estatus_sistema: true,
      };
      const docFormData = createFormDataFromObject(doc);
      const {
        ok: okDoc,
        data: dataDoc,
        message: messageDoc,
      } = await postDocument(docFormData);
      if (!okDoc) {
        this.warnings.push(messageDoc);
      } else {
        this.income.comprobantes.push(dataDoc.id);
      }
    },
    async createIncome() {
      try {
        this.alertText = 'Creando ingreso';
        this.income.autor = this.userData.id;
        this.income.institucion_educativa = this.institutionId;

        const {
          ok: okIncome,
          data: dataIncome,
          message: messageIncome,
        } = await postIncome(this.income);
        if (!okIncome) {
          this.errors.push(messageIncome);
        } else {
          this.alertText = 'Creando movimiento de cuenta';
          this.incomeId = dataIncome.id;
          const {
            ok: okIncrease,
            // data: dataIncrease,
            message: messageIncrease,
          } = await increaseAccountBalance({
            id_cuenta: this.income.cuenta_destino,
            monto: this.income.total_ingreso,
            id_autor: this.userData.id,
          });
          if (!okIncrease) {
            this.warnings.push(messageIncrease);
          } else {
            // console.log(
            //   'Movimiento de cuenta creado exitosamente.',
            //   dataIncrease
            // );
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar crear ingreso. ' + error);
        console.error('Error al intentar crear ingreso. ' + error);
      }
    },
    async updateIncome() {
      try {
        this.alertText = 'Actualizando ingreso';
        const {
          ok: okIncome,
          data: dataIncome,
          message: messageIncome,
        } = await putIncome(this.income);
        if (!okIncome) {
          this.errors.push(messageIncome);
        } else {
          this.incomeId = dataIncome.id;
          await this.correctAccountBalance();
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar ingreso. ' + error);
      }
    },
    /**
     * Actualiza los adeudos de alumno seleccionados
     */
    async updateDebts() {
      try {
        // TODO: Revisar si es necesario este paso
        // Diferenciamos los adeudos que se van a agregar
        const entityDebtsIds = this.isNewMode
          ? []
          : this.entity.adeudo.map((debt) => debt.id);

        const debtsAdded = this.isNewMode
          ? [...this.debtsSelected]
          : this.debtsSelected.filter(
              (idSelected) => !entityDebtsIds.includes(idSelected)
            );
        // console.log('Adeudos a agregar', debtsAdded);

        // Diferenciamos los adeudos que se van a remover del ingreso

        const debtsRemoved = this.isNewMode
          ? []
          : entityDebtsIds.filter(
              (debtId) => !this.debtsSelected.includes(debtId)
            );
        // console.log('Adeudos a remover', debtsRemoved);

        /** ************************* */

        for (const debtId of debtsAdded) {
          const selectedDebt = this.studentDebts.find(
            (debt) => debt.id === debtId
          );
          // const timeStatus = this.statusItem(selectedDebt);
          const debtStatusId = await this.getDebtStatusByName('Adeudo pagado');
          // const manualStatus =
          //   timeStatus === this.status.NOVALID
          //     ? this.applyPenalty
          //       ? 'Pagado'
          //       : 'Pagado'
          //     : null;
          // const debtStatusId = this.findDebtStatusId(
          //   timeStatus,
          //   this.applyPenalty,
          //   manualStatus
          // );

          // console.log('timeStatus', timeStatus);
          // console.log('debtStatusId', debtStatusId);
          const debt = {
            id: selectedDebt.id,
            nombre_adeudo: selectedDebt.nombre_adeudo,
            alumno: selectedDebt.alumno.id,
            comentarios: selectedDebt.comentarios,
            sub_total: selectedDebt.sub_total,
            total_impuestos: selectedDebt.total_impuestos,
            total_descuento: selectedDebt.total_descuento,
            total_adeudo: selectedDebt.total_adeudo,
            descuento_aplicado: selectedDebt.descuento_aplicado,
            moneda: selectedDebt.moneda.id,
            estatus: debtStatusId,
            expirado: selectedDebt.expirado,
            fecha_vencimiento: selectedDebt.fecha_vencimiento,
            fecha_pronto_pago: selectedDebt.fecha_pronto_pago,
            penalizacion: selectedDebt.penalizacion
              ? selectedDebt.penalizacion.id
              : null,
            descuento_pronto_pago: selectedDebt.descuento_pronto_pago
              ? selectedDebt.descuento_pronto_pago.id
              : null,
            institucion_educativa: this.institutionId,
            autor: this.userData.id,
          };
          const { ok: okDebt, message: messageDebt } = await putDebt(debt);
          if (!okDebt) {
            this.errors.push('No se pudo actualizar adeudo. ' + messageDebt);
          }
        }

        // Si los adeudos se quitan del ingreso
        // entonces se debe de devolver el estatus
        // Pendiente de pago al adeudo
        // console.log('Adeudos a remover', debtsRemoved);
        for (const debtToRemove of debtsRemoved) {
          // console.log('Removiendo debt', debtToRemove);
          const selectedDebt = this.studentDebts.find(
            (debt) => debt.id === debtToRemove.id
          );
          // console.log('selectedDebt', selectedDebt);
          // const debtStatusId = this.findDebtStatusId(
          //   this.status.NOVALID,
          //   this.applyPenalty
          // );
          const debtStatusId = await this.getDebtStatusByName(
            'Adeudo pendiente de pago'
          );
          const debt = {
            id: selectedDebt.id,
            nombre_adeudo: selectedDebt.nombre_adeudo,
            alumno: selectedDebt.alumno.id,
            comentarios: selectedDebt.comentarios,
            sub_total: selectedDebt.sub_total,
            total_impuestos: selectedDebt.total_impuestos,
            total_descuento: selectedDebt.total_descuento,
            total_adeudo: selectedDebt.total_adeudo,
            descuento_aplicado: selectedDebt.descuento_aplicado,
            moneda: selectedDebt.moneda.id,
            estatus: debtStatusId,
            expirado: selectedDebt.expirado,
            fecha_vencimiento: selectedDebt.fecha_vencimiento,
            fecha_pronto_pago: selectedDebt.fecha_pronto_pago,
            penalizacion: selectedDebt.penalizacion.id,
            descuento_pronto_pago: selectedDebt.descuento_pronto_pago.id,
            institucion_educativa: this.institutionId,
            autor: this.userData.id,
          };
          const { ok: okDebt, message: messageDebt } = await putDebt(debt);
          if (!okDebt) {
            this.errors.push('No se pudo actualizar adeudo. ' + messageDebt);
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar estatus de adeudo');
        console.error('Error al actualizar adeudo de alumno.', error);
      }
    },
    /**
     * Se obtienen los adeudos correspondientes al alumno seleccionado.
     * Se van a mostrar solo los que esten pendientes de pago
     */
    async getDebts(debtStatusStr = 'pendiente de pago') {
      try {
        this.loadingDebts = true;
        this.clearQty();
        this.showDebts = true;
        const pendingStatus = this.debtStatus.find((status) =>
          status.dato.toLowerCase().includes(debtStatusStr)
        );
        const {
          ok: okDebts,
          data: dataDebts,
          message: messageDebts,
        } = await fetchDebts({
          institutionId: this.institutionId,
          studentId: this.income.alumno,
          statusId: pendingStatus ? pendingStatus.id : null,
          systemStatus: true,
          limit: 1000,
        });
        if (okDebts) {
          this.studentDebts = dataDebts;
        } else {
          this.alertType = 'warning';
          this.alertText =
            'No pudieron obtener los adeudos del alumno. ' + messageDebts;
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al obtener los adeudos del alumno.', error);
      } finally {
        this.loadingDebts = false;
      }
    },
    /**
     * Se obtiene los datos fiscales del tutor responsable
     * econonómico
     */
    async getTutorTaxInfo() {
      try {
        this.loading = true;
        if (typeof this.taxInfoIssuer.uso_factura === 'number') {
          const responseCFDIUse = await getItem(
            `/app-personas/uso-factura/${this.taxInfoIssuer.uso_factura}`
          );
          if (responseCFDIUse.e) {
            console.log('Error al obtener uso factura. ' + responseCFDIUse.e);
          } else {
            this.taxInfoIssuer.uso_factura = responseCFDIUse;
          }

          // console.log('Datos fiscales emisor', responseCFDIUse);
        }

        if (this.income.alumno) {
          // console.log('Alumno seleccionado', this.income.alumno);
          const selectedStudent = this.students.find(
            (student) => student.id === this.income.alumno
          );

          const studentTutors = selectedStudent.tutores;
          // console.log('tutores', studentTutors);

          const financiallyResponsibleTutor = studentTutors.length
            ? studentTutors.find((tutor) => tutor.responsable_economico)
            : null;
          // console.log(
          //   'Tutor responsable economico',
          //   financiallyResponsibleTutor
          // );

          if (financiallyResponsibleTutor) {
            await this.getTutorPersonalData(
              financiallyResponsibleTutor.datos_personales
            );
            await this.getTutorRelationship(
              financiallyResponsibleTutor.parentesco
            );
            await this.getTutorTaxData(
              financiallyResponsibleTutor.datos_fiscales
            );
          }
        }
      } catch (error) {
        console.error(
          'Error al intentar obtener los datos fiscales del tutor.',
          error
        );
      } finally {
        this.loading = false;
      }
    },
    async getTutorPersonalData(personalDataId) {
      const responsePersonalData = await getItem(
        `/app-personas/datos-personales/${personalDataId}`
      );
      if (responsePersonalData.e) {
        console.log(
          'Error al obtener datos personales del tutor. ' +
            responsePersonalData.e
        );
      } else {
        this.tutorInfo.datos_personales = responsePersonalData;
      }
    },
    async getTutorRelationship(relationshipId) {
      const responseRelationshipData = await getItem(
        `/app-personas/parentesco/${relationshipId}`
      );
      if (responseRelationshipData.e) {
        console.log(
          'Error al obtener parentesco del tutor. ' + responseRelationshipData.e
        );
      } else {
        this.tutorInfo.parentesco = responseRelationshipData;
      }
    },
    async getTutorTaxData(taxInfoId) {
      const responseTaxData = await getItem(
        `/app-personas/datos-fiscales/${taxInfoId}`
      );
      if (responseTaxData.e) {
        console.log(
          'Error al obtener datos fiscales del tutor. ' + responseTaxData.e
        );
      } else {
        this.taxInfoReceiver = responseTaxData;
      }
    },
    filterDebtsByCategory() {
      // console.log('filterDebtsByCategory id:', this.income.categoria);
      const category = this.incomeCategories.find(
        (cat) => cat.id === this.income.categoria
      );
      // console.log('Categoria seleccionada:', category);
      if (category) {
        const debtsFiltered = this.studentDebts.filter((sd) =>
          category.dato.toLowerCase().includes(sd.comentarios.toLowerCase())
        );
        // console.log('debtsFiltered:', debtsFiltered);
        if (debtsFiltered) {
          this.studentDebts = [...debtsFiltered];
        }
      }
    },
    colorItem(item) {
      const todayFull = new Date();
      const today = this.getDateWithTimeZero(todayFull);
      const pronto = this.getDateWithTimeZeroStr(item.fecha_pronto_pago);
      const vencimiento = this.getDateWithTimeZeroStr(item.fecha_vencimiento);
      this.statusDebt = this.getStatusTime(today, pronto, vencimiento);
      return this.getColorTime(today, pronto, vencimiento);
    },
    statusItem(item) {
      const todayFull = new Date();
      const today = this.getDateWithTimeZero(todayFull);
      const pronto = this.getDateWithTimeZeroStr(item.fecha_pronto_pago);
      const vencimiento = this.getDateWithTimeZeroStr(item.fecha_vencimiento);
      return this.getStatusTime(today, pronto, vencimiento);
    },
    /**
     * Busca el id de estatus de adeudo correspondiente
     * al estatus de tiempo del adeudo.
     * EARLYPAY, PROMPTPAY, INTIME devuelven el
     * id de estatus que corresponda al estatus 'Pagado'.
     * Mientras que OVERDUE devuelve el id del estatus
     * 'Pagado con penalizacion' pero solo si el
     * usuario indica que se debe aplicar la
     * penalizacion de otra manera tambien devuelve
     * el id de estatus 'Pagado'
     * @param {number} timeStatus Estatus de tiempo
     * @param {boolean} applyPenalty Indicador de si se va aplicar penalizacion o no
     * @return {number} id de estatus de adeudo
     */
    findDebtStatusId(timeStatus, applyPenalty = false, manualStatus = null) {
      let statusStr = 'Pendiente de pago';
      if (manualStatus) {
        statusStr = manualStatus;
      } else {
        switch (timeStatus) {
          case this.status.EARLYPAY:
          case this.status.INTIME:
          case this.status.PROMPTPAY:
            statusStr = 'Pagado';
            break;
          case this.status.OVERDUE:
            statusStr = applyPenalty ? 'Pagado' : 'Pagado';
            break;
          default:
            statusStr = 'Pendiente de pago';
        }
      }
      // console.log('Dentro de findDebtStatusId statusStr', statusStr);
      const status = this.debtStatus.find((s) =>
        s.dato.toLowerCase().includes(statusStr.toLowerCase())
      );
      // console.log('Estatus encontrado', status);
      return status.id;
    },
    async getDebtStatusByName(name) {
      let statusId = null;
      const { ok: okDebtStatus, data: dataDebtStatus } =
        await fetchFiltersWithData('estatus-adeudos', {
          institutionId: this.institutionId,
          data: name,
          systemStatus: true,
          limit: 5,
        });
      const debtStatus = okDebtStatus ? dataDebtStatus : [];
      if (debtStatus.length) {
        statusId = debtStatus[0].id;
      }
      return statusId;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;

        // console.log('Tiene adeudos', this.hasDebts);

        this.income.descuento_aplicado = this.applyDiscount;
        this.income.penalizacion_aplicada = this.applyPenalty;
        this.income.is_impuestos_aplicados = this.applyTaxes;
        this.income.is_comision_aplicada = this.applyCommission;
        this.income.total_comision = this.totalCommission;

        this.income.total_impuestos = this.applyTaxes
          ? this.income.total_impuestos
          : 0.0;
        this.income.total_penalizaciones = this.applyPenalty
          ? this.income.total_penalizaciones
          : 0.0;
        this.income.total_descuento = this.applyDiscount
          ? this.income.total_descuento
          : 0.0;
        this.income.total_comision = this.applyCommission
          ? this.totalCommission
          : 0.0;

        this.income.adeudo = this.hasDebts ? [...this.debtsSelected] : null;

        // console.log('Dentro de save. Ingreso a enviar', this.income);
        if (this.receipt !== null && !this.receipt.id) {
          // console.log('Se debe crear documento');
          await this.createDoc();
        }
        if (this.isNewMode && !this.incomeId) {
          // console.log('Se debe crear ingreso');
          await this.createIncome();
        } else {
          // console.log('Se debe actualizar ingreso');
          await this.updateIncome();
        }

        if (this.errors.length === 0 && this.hasDebts) {
          // console.log('Se deben actualizar adeudos');
          await this.updateDebts();
        }
        // if (this.errors.length === 0 && this.income.alumno !== null) {
        //   // console.log('Se debe enviar comprobante');
        //   await this.sendProofOfPayment();
        // }
        // TODO: Revisar que hacer cuando se des selecciona un documento
        // principalmente el eliminar documento permanentemente

        // TODO: Agregar facturacion
        if (this.errors.length === 0 && this.incomeId !== null) {
          await this.createIncomeBill();
        }

        this.loading = false;
        if (this.errors.length) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
      }
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    fillDebtValues() {
      // console.log('Adeudo seleccionado fillDebtValues: ', this.debtsSelected);
      const today = new Date();
      let subtotal = 0.0;
      let impuestos = 0.0;
      let descuento = 0.0;
      let penalizacion = 0.0;
      let total = 0.0;
      for (const debtId of this.debtsSelected) {
        // console.log('debtId', debtId);
        const debtSelected = this.studentDebts.find(
          (debt) => debt.id === debtId
        );
        let discountAmount = 0.0;
        let penaltyAmount = 0.0;

        if (debtSelected.descuento_pronto_pago) {
          const discountStartDate = new Date(
            debtSelected.descuento_pronto_pago.fecha_inicio
          );
          const discountEndDate = new Date(
            debtSelected.descuento_pronto_pago.fecha_fin
          );
          const promtPayDate = new Date(debtSelected.fecha_pronto_pago);

          if (
            today >= discountStartDate &&
            today <= discountEndDate &&
            today < promtPayDate
          ) {
            // console.log('se debe calcular descuento');
            const percent = parseFloat(
              debtSelected.descuento_pronto_pago.porcentaje_descuento
            );
            discountAmount =
              parseFloat(debtSelected.sub_total) *
              (percent > 1 ? percent / 100 : percent);
          }
        }

        if (debtSelected.penalizacion) {
          // console.log('se debe calcular penalizacion');
          const overdueDate = new Date(debtSelected.fecha_vencimiento);
          if (today > overdueDate) {
            const mount = parseFloat(debtSelected.penalizacion.monto);
            penaltyAmount = mount;
          }
        }

        const subtotalDebt = parseFloat(debtSelected.sub_total);
        const taxAmount = subtotalDebt * 0.16;

        // console.log('debtSelected', debtSelected);
        subtotal += subtotalDebt;
        impuestos += taxAmount;
        descuento += discountAmount;
        penalizacion += penaltyAmount;
        total +=
          subtotalDebt +
          (this.applyTaxes ? taxAmount : 0.0) +
          (this.applyPenalty ? penaltyAmount : 0.0) -
          (this.applyDiscount ? discountAmount : 0.0);
        // console.log('subtotal', subtotal);
        // console.log('impuestos', impuestos);
        // console.log('descuanto', descuento);
        // console.log('penalizacion', penalizacion);
        // console.log('total', total);

        this.income.sub_total = subtotal.toFixed(2);
        this.income.total_impuestos = impuestos.toFixed(2);
        this.income.total_descuento = descuento.toFixed(2);
        this.income.total_penalizaciones = penalizacion.toFixed(2);
        this.income.total_ingreso = total.toFixed(2);
        // console.log('Income totales', this.income);
      }
    },
    clearQty() {
      this.debtsSelected = [];
      this.studentDebts = [];
      this.income.sub_total = 0.0;
      this.income.total_impuestos = 0.0;
      this.income.total_descuento = 0.0;
      this.income.total_ingreso = 0.0;
      this.income.total_penalizaciones = 0.0;
      this.income.penalizacion_aplicada = false;

      this.penaltyAmount = 0.0;
    },
    calculateTotalSimple() {
      // console.log('Dentro calculateTotalSimple', this.income);
      // console.log('applyTaxes', this.applyTaxes);
      // console.log('applyDisccount', this.applyDiscount);
      // console.log('applyPenalty', this.applyPenalty);
      // this.applyTaxes = !this.applyTaxes;
      // this.applyDiscount = !this.applyDiscount;
      // this.applyPenalty = !this.applyPenalty;

      let subtotal = this.income.sub_total;

      if (this.applyTaxes && this.income.total_impuestos == 0.0) {
        const calculatedImpuestos = subtotal * 0.16;
        this.income.total_impuestos = calculatedImpuestos;
      }

      if (this.applyPenalty && this.income.total_penalizaciones == 0.0) {
        this.calculatePenaltyTotal();
      }

      if (this.applyDiscount && this.income.total_descuento == 0.0) {
        this.calculateDiscountTotal();
      }

      // let {
      //   total_impuestos: impuestos,
      //   total_descuento: descuento,
      //   total_penalizaciones: penalizacion,
      // } = this.income;
      const impuestos = parseFloat(this.income.total_impuestos);
      const descuento = parseFloat(this.income.total_descuento);
      const penalizacion = parseFloat(this.income.total_penalizaciones);
      const comision = parseFloat(this.totalCommission);

      const total =
        subtotal +
        (this.applyTaxes ? impuestos : 0.0) +
        (this.applyCommission ? comision : 0.0) +
        (this.applyPenalty ? penalizacion : 0.0) -
        (this.applyDiscount ? descuento : 0.0);
      this.income.total_ingreso = total;
    },
    // calculateTotal() {
    //   // console.log('Dentro de calculateTotal');
    //   this.income.total_impuestos =
    //     this.income.sub_total * (this.applyTaxes ? 0.16 : 0.0);

    //   let {
    //     sub_total: subtotal,
    //     total_impuestos: impuestos,
    //     total_descuento: descuento,
    //     total_ingreso: total,
    //   } = this.income;
    //   let penalizacion = 0.0;
    //   impuestos = subtotal * (this.applyTaxes ? 0.16 : 0.0);
    //   total = subtotal + impuestos - descuento;
    //   this.income.total_impuestos = impuestos;
    //   this.income.total_ingreso = total;

    //   if (this.debtSelected) {
    //     this.statusDebt = this.statusItem(this.debt);
    //     // console.log('estatusDebt', this.statusDebt);
    //     if (this.statusDebt === this.status.OVERDUE) {
    //       this.penaltyAmount = parseFloat(this.debtSelected.penalizacion.monto);
    //       penalizacion = this.penaltyAmount;
    //       this.income.total_penalizaciones = penalizacion;
    //       this.income.penalizacion_aplicada = true;
    //       this.income.total_ingreso =
    //         subtotal + penalizacion + impuestos - descuento;
    //     } else {
    //       this.penaltyAmount = 0.0;
    //       this.income.total_penalizaciones = 0.0;
    //       this.income.penalizacion_aplicada = false;
    //     }
    //   }
    // },
    calculatePenaltyTotal() {
      const today = new Date();
      let penalizacion = 0.0;
      for (const debtId of this.debtsSelected) {
        const debtSelected = this.studentDebts.find(
          (debt) => debt.id === debtId
        );
        let penaltyAmount = 0.0;
        if (debtSelected.penalizacion) {
          // console.log('se debe calcular penalizacion');
          const overdueDate = new Date(debtSelected.fecha_vencimiento);
          if (today > overdueDate) {
            const mount = parseFloat(debtSelected.penalizacion.monto);
            penaltyAmount = mount;
          }
          penalizacion += penaltyAmount;
        }
      }
      this.income.total_penalizaciones = penalizacion.toFixed(2);
    },
    calculateDiscountTotal() {
      const today = new Date();
      let descuento = 0.0;
      for (const debtId of this.debtsSelected) {
        // console.log('debtId', debtId);
        const debtSelected = this.studentDebts.find(
          (debt) => debt.id === debtId
        );
        let discountAmount = 0.0;

        if (debtSelected.descuento_pronto_pago) {
          const discountStartDate = new Date(
            debtSelected.descuento_pronto_pago.fecha_inicio
          );
          const discountEndDate = new Date(
            debtSelected.descuento_pronto_pago.fecha_fin
          );
          const promtPayDate = new Date(debtSelected.fecha_pronto_pago);

          if (
            today >= discountStartDate &&
            today <= discountEndDate &&
            today < promtPayDate
          ) {
            // console.log('se debe calcular descuento');
            const percent = parseFloat(
              debtSelected.descuento_pronto_pago.porcentaje_descuento
            );
            discountAmount =
              parseFloat(debtSelected.sub_total) *
              (percent > 1 ? percent / 100 : percent);
          }
        }

        descuento += discountAmount;

        // console.log('subtotal', subtotal);
        // console.log('impuestos', impuestos);
        // console.log('descuanto', descuento);
        // console.log('penalizacion', penalizacion);
        // console.log('total', total);

        this.income.total_descuento = descuento.toFixed(2);
      }
    },
    calculateCommissionTotal() {
      if (this.isCardPayment && this.applyCommission) {
        const subtotal = this.income.sub_total;
        const percentage =
          this.commissionPercentage > 0
            ? this.commissionPercentage / 100
            : this.commissionPercentage;
        const total = subtotal * percentage;
        this.totalCommission = total.toFixed(2);
      }
      // else {
      //   this.applyCommission = false;
      //   this.commissionPercentage = 3.5;
      //   this.totalCommission = 0.0;
      //   this.calculateTotalSimple();
      // }
    },
    resetCommission() {
      // this.applyCommission = false;
      this.commissionPercentage = 3.5;
      this.totalCommission = 0.0;
    },
    updateCommission() {
      // console.log('updateCommission');

      this.calculateCommissionTotal();
      this.calculateTotalSimple();
    },
    calculateAmounts() {
      this.calculateCommissionTotal();
      this.calculateTotalSimple();
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.income = {
        alumno: null,
        adeudo: [],
        categoria: null,
        sub_total: 0.0,
        total_impuestos: 0.0,
        costo_envio: 0.0,
        total_descuento: 0.0,
        total_penalizaciones: 0.0,
        total_comision: 0.0,
        total_ingreso: 0.0, // se va a calcular de la suma de subtotal + impuestos menos el valor de descuento
        descuento_aplicado: false,
        penalizacion_aplicada: false,
        is_impuestos_aplicados: false,
        is_comision_aplicada: false,
        penalizacion: null,
        forma_de_pago: null,
        moneda: null,
        cuenta_destino: null,
        estatus: null,
        comprobantes: [],
        institucion_educativa: this.institutionId,
        autor: this.userData.id, // debe usar el de usuario logueado
        estatus_sistema: true,
        comentarios: '',
        facturado: false,
        id_cfdi: '',
      };
      this.clearQty();
    },
    async fillIncome() {
      this.incomeId = this.entity.id;

      this.incomeDate = this.getDateWithTimeZeroFullStr(
        this.entity.fecha_elaboracion
      );

      const {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_ingreso: total,
        total_penalizaciones: penalizaciones,
        total_comision: comision,
      } = this.entity;

      // console.log('Llenando income');
      // console.log(
      //   'subtotal:',
      //   subtotal,
      //   'impuestos:',
      //   impuestos,
      //   'descuento:',
      //   descuento,
      //   'penalizaciones:',
      //   penalizaciones
      // );
      if (this.entity.alumno) {
        this.income.alumno = this.entity.alumno.id;
        await this.getDebts();
        // console.log('showDebts', this.showDebts);
        // console.log('allStudentDebts', this.studentDebts);
        // console.log('Tiene adeudos?', this.hasDebts);
        if (this.hasDebts) {
          this.studentDebts.push(...this.entity.adeudo);
          this.debtsSelected = this.entity.adeudo.map((debt) => debt.id);
        }
      }
      this.applyPenalty = this.entity.penalizacion_aplicada;
      this.applyDiscount = this.entity.descuento_aplicado;
      this.applyTaxes = this.entity.is_impuestos_aplicados;
      this.applyCommission = this.entity.is_comision_aplicada;

      this.totalCommission = comision;

      this.income = {
        id: this.entity.id,
        alumno: this.entity.alumno ? this.entity.alumno.id : null,
        adeudo: this.hasDebts ? [...this.debtsSelected] : null,
        categoria: this.entity.categoria.id,
        sub_total: parseFloat(subtotal),
        total_impuestos: parseFloat(impuestos),
        costo_envio: this.entity.costo_envio,
        total_descuento: parseFloat(descuento),
        total_penalizaciones: parseFloat(penalizaciones),
        total_comision: parseFloat(comision),
        total_ingreso: parseFloat(total), // se va a calcular de la suma de subtotal + impuestos menos el valor de descuento
        descuento_aplicado: this.applyDiscount,
        penalizacion_aplicada: this.applyPenalty,
        is_impuestos_aplicados: this.applyTaxes,
        is_comision_aplicada: this.applyCommission,
        comentarios: this.entity.comentarios,
        penalizacion: this.entity.penalizacion // En des uso, ya que el monto total de penalizacion depende de las penalizaciones de los adeudos
          ? this.entity.penalizacion.id
          : null,
        forma_de_pago: this.entity.forma_de_pago.id,
        moneda: this.entity.moneda.id,
        cuenta_destino: this.entity.cuenta_destino.id,
        estatus: this.entity.estatus.id,
        autor: this.userData.id, // debe usar el de usuario logueado
        institucion_educativa: this.institutionId,
        comprobantes: this.entity.comprobantes.map((c) => c.id),
        facturado: this.entity.facturado,
      };
      // console.log('income llenada', this.income);
      //await this.getDebts()

      this.receipt = this.findReceiptPDF(this.entity.comprobantes);
    },
    /** Funciones para manejar comprobantes */
    saveReceipt(file) {
      this.receipt = file;
    },
    clearReceipt(file) {
      this.receipt = null;
      if (!this.isNewMode && file.id !== null) {
        this.receiptIdToDelete = file.id;
      }
    },
    findReceiptPDF(receipts = []) {
      let pdfReceipt = null;
      if (receipts.length) {
        const findedReceipt = receipts.find((r) =>
          r.nombre_documento.includes('.pdf')
        );
        if (findedReceipt) {
          pdfReceipt = findedReceipt;
        }
      }
      return pdfReceipt;
    },
    async assignReceiptToIncome(receiptId, incomeId) {
      try {
        const responseData = await postItem(
          '/app-administracion/helpers/agregar-comprobantes-ingreso',
          {
            id_ingreso: incomeId,
            id_comprobante: receiptId,
          }
        );
        return responseData;
      } catch (error) {
        console.error('Error al intentar asignar comprobante a ingreso', error);
      }
    },
    async removeReceiptFromIncome(receiptId, incomeId) {
      try {
        const response = await api.post(
          '/administracion/remove-comprobante-ingreso',
          {
            id_ingreso: incomeId,
            id_comprobante: receiptId,
          }
        );
        const responseData = await response.data;
        return responseData;
      } catch (error) {
        console.error(
          'Error al intentar remover el comprobante de ingreso',
          error
        );
      }
    },

    async sendProofOfPayment() {
      try {
        const incomeStatusSelected = this.incomeStatus.find(
          (iStatus) => iStatus.id === this.income.estatus
        );
        const validStatus = ['pagado', 'completado'];
        if (
          validStatus.some((vStatus) =>
            incomeStatusSelected.dato.toLowerCase().includes(vStatus)
          )
        ) {
          const { ok, message } = await sendProofOfIncome(this.incomeId);
          if (!ok) {
            this.warnings.push(message);
          } else {
            this.alertText = 'Comprobante de pago enviado por correo';
          }
        }
      } catch (error) {
        this.warnings.push(
          'Error al intentar enviar comprobante de pago' + error
        );
      }
    },
    /**
     * Corrección de balance de cuenta. Toma el monto anterior
     * y lo resta al balance, luego toma el monto actualizado
     * y lo suma al balance.
     */
    async correctAccountBalance() {
      const amountToCorrect = parseFloat(this.entity.total_ingreso);
      const correctedAmount = this.income.total_ingreso;
      const objForMovement = {
        id_cuenta: this.income.cuenta_destino,
        monto: amountToCorrect,
        id_autor: this.userData.id,
        comentarios: `Salida por corrección de ingreso (ID ${this.incomeId})`,
      };
      const { ok: okDecrease, message: messageDecrease } =
        await decreaseAccountBalance(objForMovement);
      if (!okDecrease) {
        this.warnings.push(messageDecrease);
      }

      objForMovement.monto = correctedAmount;
      objForMovement.comentarios = `Entrada por corrección de ingreso (ID ${this.incomeId})`;
      const { ok: okIncrease, message: messageIncrease } =
        await increaseAccountBalance(objForMovement);
      if (!okIncrease) {
        this.warnings.push(messageIncrease);
      }
    },
    async createIncomeBill() {
      if (this.toBill && (this.isNewMode ? true : !this.entity.facturado)) {
        // console.log('Se debe mandar a facturar');
        const { ok, message } = await postCreateIncomeBill(this.incomeId);
        if (!ok) {
          this.warnings.push(message);
        } else {
          this.alertText = 'Factura creada exitosamente';
          this.warnings.push('Factura creada exitosamente');
        }
      }
    },
    activeItems(array) {
      return array.filter((item) => item.estatus_sistema === true);
    },
    getDefaultText(value) {
      return value !== null && value !== undefined && value !== ''
        ? value
        : 'No especificado';
    },
    openTaxInfoModal() {
      this.openTaxInfo = true;
    },
    closeTaxInfoModal() {
      this.openTaxInfo = false;
    },
    async updateTaxInfo() {
      this.loading = true;
      const dataToSend = {
        ...this.taxInfoReceiver,
        uso_factura: this.taxInfoReceiver.uso_factura.id,
      };
      const { ok, message } = await putTaxInfo(dataToSend);
      // console.log('data', data);

      if (ok) {
        await this.getTutorTaxData(this.taxInfoReceiver.id);
        this.closeTaxInfoModal();
      } else {
        this.loading = false;
        this.taxInfoMessage = message;
      }
      this.loading = false;
    },
  },
};
